<template>
    <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
        <v-progress-circular :size="70" :width="4" :color="gray" indeterminate></v-progress-circular>
    </div>
</template>

<script>
import xeonserv from '../../apis/xeonserv'

export default {
    created() {
        const req = {}
        switch(this.$route.params.provider) {
            case 'paypal':
                req.token = this.$route.query.token
                break
            case 'paysafecard':
                req.id = this.$route.query.id
                break
            case 'stripe':
                req.id = this.$route.query.id
                break
        }
        xeonserv().post('/payments/return/' + this.$route.params.provider, req).then(() => window.close(), () => window.close())
    }
}
</script>